<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <vuexy-logo /> -->
        <!-- <h2 class="brand-text text-primary ml-1">Vuexy</h2> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-2">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
            Buat Password Anda
          </b-card-title>
          <b-card-text class="mb-2">
            Silahkan masukan passwod dan Konfirmasi password untuk login ke akun
            anda
          </b-card-text>

          <b-form class="auth-login-form mt-2">
            <!-- email -->
            <b-form-group label="Password" label-for="login-Password">
              <b-input-group class="input-group-merge">
                <b-form-input
                  v-model="formDaftar.password"
                  class="form-control-merge"
                  name="login-password"
                  placeholder="Password"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <!-- forgot password -->
            <b-form-group
              label="Konfirmasi Password"
              label-for="login-Konfirmasi-Password"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  v-model="formDaftar.confirm_password"
                  class="form-control-merge"
                  name="confirm-password"
                  placeholder="Password"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <!-- submit buttons -->
            <b-button variant="warning" block @click="createPassword()">
              Submit
            </b-button>
          </b-form>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";

import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/login-default.svg"),

      // validation rules
      required,
      email,

      verifyToken: {
        registration_token: "",
        email: "",
      },

      formDaftar: {
        registration_token: "",
        idRegister: "",
        password: "",
        confirm_password: "",
      },

      auth: {
        id: 1,
        fullName: "John Doe",
        username: "johndoe",
        password: "admin",
        // eslint-disable-next-line global-require
        avatar: require("@/assets/images/avatars/13-small.png"),
        email: "admin@demo.com",
        role: "admin",
        ability: [
          {
            action: "manage",
            subject: "all",
          },
        ],
      },
    };
  },

  beforeMount() {
    this.verifyToken.registration_token = this.$route.params.regisToken;
    this.formDaftar.registration_token = this.$route.params.regisToken;
    this.verifyToken.email = this.$route.params.email;

    this.getVerifyToken();
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    getVerifyToken() {
      return new Promise(() => {
        axios
          .post(
            "user/verification/" +
              this.verifyToken.email +
              "/" +
              this.verifyToken.registration_token
          )
          .then((res) => {
            this.formDaftar.idRegister = res.data.id;
            this.formDaftar.registration_token = res.data.registration_token;

            this.show = false;
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
            }
            this.show = false;
          });
      });
    },

    createPassword() {
      return new Promise(() => {
        axios
          .post(
            "user/registration/create_password?user_id=" +
              this.formDaftar.idRegister +
              "&password=" +
              this.formDaftar.password +
              "&confirm_password=" +
              this.formDaftar.confirm_password +
              "&registration_token=" +
              this.formDaftar.registration_token
          )
          .then((res) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Buat Password Berhasil`,
                icon: "CheckCircleIcon",
                variant: "success",
                text: `Silahkan login menggunakan NIK dan Password yang telah anda buat`,
              },
            });
            this.$router.push("/login");
            this.show = false;
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
            }
            this.show = false;
          });
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
